import * as React from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, companies }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Floor" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {companies.map((c, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{c.id}</TableCell>
                <TableCell>{c.name}</TableCell>
                <TableCell>{c.floor}</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/app/buildings/${c.building_id}/companies/${c.id}/edit`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Edit" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, building, companies }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-suitcase"></i> "{building.name}" -{' '}
          <FormattedMessage id="Companies" />
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/buildings/${building.id}/companies/create`}
            >
              <FormattedMessage id="Create company" />
            </Button>
            <hr />
          </div>
          {!companies.length && <NoRecords />}
          {!!companies.length && (
            <RenderTable {...{ onPageChange, pages, page, companies }} />
          )}
        </React.Fragment>
      }
    />
  );
};
